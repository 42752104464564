<template>
    <div>
        <v-row class="table-actions-row">
            <v-col sm="4" cols="12" class="pl-0">
                <breadcrumbs :items="crumbs" />
            </v-col>
            <v-col sm="8" class="d-flex justify-end align-center">
                <CButton
                    @click="
                        clearCurrentItem()
                        showEntityModal = true
                    "
                    class="add-product-modal"
                    color="info"
                    ><i class="la la-plus"></i>
                    Добавить категорию товаров
                </CButton>
            </v-col>
        </v-row>
        <localization :language="'ru-RU'">
            <intl :locale="'ru'">
                <Grid
                    ref="grid"
                    class="products products-categories"
                    :pageable="gridPageable"
                    :sortable="true"
                    :filterable="true"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :take="take"
                    :skip="skip"
                    :sort="sort"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    :expand-field="'expanded'"
                    @expandchange="expandChange"
                    @sortchange="sortChange"
                >
                    <template v-slot:actions="{ props: { dataItem } }">
                        <td>
                            <div class="actionsColumn">
                                <actions-dropdown
                                    :actions="getActions(dataItem)"
                                />
                            </div>
                        </td>
                    </template>
                    <template v-slot:hidden_filter="{ methods, props }">
                        <boolean-filter
                            @change="
                                selectFilter('is_hidden', $event.value.value)
                            "
                        />
                    </template>
                    <template v-slot:hidden="{ props: { dataItem } }">
                        <td>
                            <div>
                                <span
                                    class="m-badge m-badge--wide m-badge--wide"
                                    :class="{
                                        'm-badge--success': dataItem.is_hidden,
                                        'm-badge--danger': !dataItem.is_hidden,
                                    }"
                                >
                                    {{ dataItem.is_hidden ? 'ДА' : 'НЕТ' }}
                                </span>
                            </div>
                        </td>
                    </template>
                    <template v-slot:type="{ props: { dataItem } }">
                        <td>
                            {{
                                dataItem.type === 'dealer'
                                    ? 'Дилер'
                                    : 'Структурное подразделение'
                            }}
                        </td>
                    </template>
                    <template v-slot:typeFilter="{ methods, props }">
                        <dropdownlist
                            class="categories-filter"
                            :data-items="types"
                            :text-field="'name'"
                            :data-item-key="'id'"
                            :default-item="{ id: null, name: 'Все' }"
                            @change="selectType"
                        />
                    </template>
                </Grid>
            </intl>
        </localization>
        <category-modal
            v-if="showEntityModal"
            :current-item="currentItem"
            :show-dialog="showEntityModal"
            @click:outside="showEntityModal = false"
            @stored="entityStored($event)"
            @close="showEntityModal = false"
        />
    </div>
</template>

<script>
    import TableMixin from '@/views/grid_elements/mixins/TableMixin'
    import ResizableTableMixin from '@/views/grid_elements/mixins/ResizableTableMixin'
    import { Grid } from '@progress/kendo-vue-grid'
    import {
        IntlProvider,
        load,
        LocalizationProvider,
        loadMessages,
    } from '@progress/kendo-vue-intl'
    import { DropDownList } from '@progress/kendo-vue-dropdowns'
    import { mapGetters } from 'vuex'
    import ActionsDropdown from '@/views/grid_elements/ActionsDropdown'
    import { showConfirm } from '@/lib/swal2'
    import Breadcrumbs from '@/views/components/breadcrumbs'
    import CategoryCreateUpdateModal from '../modals/CategoryCreateUpdateModal'
    import api from '@/api'
    import { showMessage } from '@/lib/toasted'
    import BooleanFilter from '../../../components/BooleanFilter'
    import { toDataSourceRequestString } from '@progress/kendo-data-query'

    export default {
        name: 'categories',
        components: {
            Grid: Grid,
            dropdownlist: DropDownList,
            BooleanFilter,
            ActionsDropdown,
            breadcrumbs: Breadcrumbs,
            'category-modal': CategoryCreateUpdateModal,
            localization: LocalizationProvider,
            intl: IntlProvider,
        },
        mixins: [TableMixin, ResizableTableMixin],
        mounted() {
            this.getData()
        },
        data() {
            return {
                skip: 0,
                take: 10,
                pageSize: 10,
                gridPageable: { pageSizes: true },
                total: 0,
                columns: [
                    {
                        field: 'id',
                        title: 'Действия',
                        cell: 'actions',
                        filterable: false,
                        sortable: false,
                    },
                    {
                        field: 'name',
                        title: 'Название',
                    },
                    {
                        field: 'products_number',
                        title: 'Количество товаров',
                        filter: 'numeric',
                        type: 'numeric',
                    },
                    {
                        field: 'section_name',
                        title: 'Раздел',
                    },
                    {
                        field: 'is_hidden',
                        title: 'Скрыта?',
                        cell: 'hidden',
                        type: 'boolean',
                        filterCell: 'hidden_filter',
                        filter: 'boolean',
                    },
                    {
                        field: 'type',
                        title: 'Тип',
                        cell: 'type',
                        filterCell: 'typeFilter',
                        filter: 'text',
                    },
                ],
                actions: [
                    {
                        text: 'Сделать не популярным',
                        icon: 'la la-star-o',
                    },
                    {
                        text: 'Сделать недоступным',
                        icon: 'la la-ban',
                    },
                    {
                        text: 'Скрыть товар',
                        icon: 'la la-eye-slash',
                    },
                    {
                        text: 'Редактировать',
                        icon: 'la la-pencil',
                    },
                    {
                        text: 'Удалить',
                        icon: 'la la-trash',
                    },
                ],
                types: [
                    {
                        id: 'dealer',
                        name: 'Дилер',
                    },
                    {
                        id: 'subdivision',
                        name: 'СП',
                    },
                ],
                currentFile: null,
                tab: null,
            }
        },
        computed: {
            ...mapGetters('catalog', ['productCategoriesShort', 'sectionList']),
        },
        methods: {
            fetchData: api.catalog.categories,
            removeProductImage(productId, imageId) {
                if (
                    showConfirm('Вы действительно хотите удалить изображение?')
                ) {
                }
            },
            setCurrentFile(file) {
                this.currentFile = file
            },
            clearCurrentFile() {
                this.currentFile = null
            },
            async toggleHidden(item) {
                const { is_hidden, id } = item
                const message = is_hidden
                    ? 'Вы действительно хотите сделать данную категорию товара видимой?'
                    : 'Вы действительно хотите сделать данную категорию товара скрытой?'
                const { isConfirmed } = await showConfirm(message)

                if (isConfirmed) {
                    const { data } = await api.catalog.toggleCategoryProperty(
                        id,
                        'is-hidden'
                    )
                    await this.getData()
                }
            },
            async deleteEntity(item) {
                const { isConfirmed } = await showConfirm(
                    'Вы действительно хотите удалить категорию товаров?'
                )

                if (isConfirmed) {
                    const {
                        data: { status, message },
                    } = await api.catalog.deleteCategory(item.id)
                    showMessage(status, message)

                    this.updateTable()
                }
            },
            prepareFetchParams() {
                return [
                    this.page,
                    this.take,
                    toDataSourceRequestString({
                        filter: this.filter,
                        sort: this.sort,
                    }),
                ]
            },
            getActions(dataItem) {
                const actions = [
                    {
                        text: 'Редактировать',
                        icon: 'la la-pencil',
                        handler: () => {
                            this.setCurrentItem(dataItem)
                            this.showEntityModal = true
                        },
                    },
                    {
                        text: dataItem.is_hidden
                            ? 'Сделать видимой'
                            : 'Скрыть категорию',
                        icon:
                            'la ' +
                            (dataItem.is_hidden ? 'la-eye' : 'la-eye-slash'),
                        handler: () => this.toggleHidden(dataItem),
                    },
                ]

                // if (!parseInt(dataItem.products_number)) {
                actions.push({
                    text: 'Удалить',
                    icon: 'la la-trash',
                    handler: () => this.deleteEntity(dataItem),
                })
                // }

                return actions
            },
            selectType({ value }) {
                if (value.id) {
                    this.setFilter('type', 'eq', value.id)
                } else {
                    this.removeFilter('type')
                }

                this.updateTable(true)
            },
        },
    }
</script>

<style scoped></style>
