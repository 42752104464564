var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"table-actions-row"},[_c('v-col',{staticClass:"pl-0",attrs:{"sm":"4","cols":"12"}},[_c('breadcrumbs',{attrs:{"items":_vm.crumbs}})],1),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"sm":"8"}},[_c('CButton',{staticClass:"add-product-modal",attrs:{"color":"info"},on:{"click":function($event){_vm.clearCurrentItem()
                    _vm.showEntityModal = true}}},[_c('i',{staticClass:"la la-plus"}),_vm._v(" Добавить категорию товаров ")])],1)],1),_c('localization',{attrs:{"language":'ru-RU'}},[_c('intl',{attrs:{"locale":'ru'}},[_c('Grid',{ref:"grid",staticClass:"products products-categories",attrs:{"pageable":_vm.gridPageable,"sortable":true,"filterable":true,"filter":_vm.filter,"take":_vm.take,"skip":_vm.skip,"sort":_vm.sort,"page-size":_vm.pageSize,"total":_vm.total,"data-items":_vm.dataResult,"columns":_vm.columns,"expand-field":'expanded'},on:{"datastatechange":_vm.dataStateChange,"expandchange":_vm.expandChange,"sortchange":_vm.sortChange},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
                    var dataItem = ref.props.dataItem;
return [_c('td',[_c('div',{staticClass:"actionsColumn"},[_c('actions-dropdown',{attrs:{"actions":_vm.getActions(dataItem)}})],1)])]}},{key:"hidden_filter",fn:function(ref){
                    var methods = ref.methods;
                    var props = ref.props;
return [_c('boolean-filter',{on:{"change":function($event){return _vm.selectFilter('is_hidden', $event.value.value)}}})]}},{key:"hidden",fn:function(ref){
                    var dataItem = ref.props.dataItem;
return [_c('td',[_c('div',[_c('span',{staticClass:"m-badge m-badge--wide m-badge--wide",class:{
                                    'm-badge--success': dataItem.is_hidden,
                                    'm-badge--danger': !dataItem.is_hidden,
                                }},[_vm._v(" "+_vm._s(dataItem.is_hidden ? 'ДА' : 'НЕТ')+" ")])])])]}},{key:"type",fn:function(ref){
                                var dataItem = ref.props.dataItem;
return [_c('td',[_vm._v(" "+_vm._s(dataItem.type === 'dealer' ? 'Дилер' : 'Структурное подразделение')+" ")])]}},{key:"typeFilter",fn:function(ref){
                                var methods = ref.methods;
                                var props = ref.props;
return [_c('dropdownlist',{staticClass:"categories-filter",attrs:{"data-items":_vm.types,"text-field":'name',"data-item-key":'id',"default-item":{ id: null, name: 'Все' }},on:{"change":_vm.selectType}})]}}])})],1)],1),(_vm.showEntityModal)?_c('category-modal',{attrs:{"current-item":_vm.currentItem,"show-dialog":_vm.showEntityModal},on:{"click:outside":function($event){_vm.showEntityModal = false},"stored":function($event){return _vm.entityStored($event)},"close":function($event){_vm.showEntityModal = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }